<template>
  <div>
    <b-table
        hover
        :items="getUserDataTable"
        :fields="fields"
        :current-page="userList.currentPage"
        :per-page="userList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Пользователи отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ row.item }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button variant="primary"
                  title="Просмотр параметров"
                  @click="goToDetailPage(row.item)">
          <b-icon-eye font-scale="0.75"></b-icon-eye>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="userList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="userList.currentPage"
          class="my-0 ml-auto"
          :total-rows="userList.totalCount"
          :per-page="userList.perPage"
          aria-controls="template-table"
          @input="getUserDataTable">
      </b-pagination>
    </div>
  </div>
</template>
<script>
import userApi from '@/modules/user-params';

export default {
  name: 'user-list',
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Идентификатор пользователя'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      userList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  methods: {
    getUserDataTable(context) {
      return userApi.getUsers(context.currentPage, context.perPage)
          .then(resp => {
            this.userList.totalCount = resp.data.totalCount;
            return resp.data.items;
          });
    },
    goToDetailPage(id) {
      this.$router.push(`/users/${id}`);
    }
  }
}
</script>
